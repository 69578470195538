<template>
	<div class="view-wrapper view-list-wrapper">
		<div class="page-top-bg-addition" />
		<div class="mcontainer">
			<h1 class="title page-title">{{ itemsName.mult }}</h1>
			<div class="view-content-card card content-row">
				<div class="card-content">
					<Filterbar
						:items-loading="itemsLoading"
						:filters="filters"
						:category-filter="true"
						:price-filter="true"
						:categories-list="categoriesList"
						@setFilters="setFilters"
					/>
					<!-- :submitButton="true" -->

					<ItemsTableContainer
						ref="ItemsTableContainer"
						:items-loading="itemsLoading"
						:items-saving="itemsSaving"
						:table-data="itemsList"
						:table-settings="tableSettings"
						:items-name="itemsName"
						:edit-by-alias="true"
						@event="handleEvent"
					/>

					<PaginationContainer
						:items-name="itemsName"
						:filters="filters"
						:meta="meta"
						@setFilters="setFilters"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { itemsDataMixin, eventHandler, itemsFetchSetMixin } from '@/mixins';
// import { orderStatusesList } from '@/constants/global';

export default {
	components: {
		Filterbar: () => import('../../components/Filterbar.vue'),
		ItemsTableContainer: () => import('../../components/ItemsTableContainer.vue'),
		PaginationContainer: () => import('../../components/PaginationContainer.vue')
	},
	mixins: [itemsDataMixin, eventHandler, itemsFetchSetMixin],

	computed: {
		...mapState({
			globalFilters: state => state.global.globalFilters,

			meta: state => state.products.fetchedMeta,
			filters: state => state.products.filters,
			itemsLoading: state => state.products.isLoading,
			itemsSaving: state => state.products.isSaving,
			itemsList: state => state.products.itemsList,

			categoriesLoading: state => state.categories.isLoading,
			categoriesList: state => state.categories.itemsList
		}),

		/*flatproductsList() {
			return this.$store.getters['products/flatproductsList'];
		},*/

		initialSetFetchSettings: () => [
			{
				action: 'fetch_categories',
				payload: { params: { max: -1, withChildren: false } },
				clean_up: 'set_categories'
			}
		],

		itemsName() {
			return {
				one: 'Товар',
				mult: 'Товары'
			};
		},

		tableSettings() {
			return {
				columns: [
					{ prop: 'id', label: '№', sortable: true, width: 60 },
					// { prop: 'phone_number', label: 'Телефон' },
					{
						prop: 'title_ru',
						label: 'Название',
						sortable: true,
						min_width: 160
					},
					{
						prop: 'category.title_ru',
						label: 'Категория',
						sortable: true,
						meta: { sortBy: 'category_id' }
					},
					{
						prop: 'pictures',
						label: 'Фото',
						// sortable: true,
						meta: {
							sortBy: 'category_id',
							boolean: { trueVal: 'Да', falseVal: 'Нет' }
						}
					},
					{ prop: 'price', label: 'Цена', sortable: true, width: 80 },
					{ prop: 'code', label: 'Код товара', sortable: true },
					// { prop: 'quantity', label: 'Количество', sortable: true },
					// { prop: 'quantity', label: 'Количество', sortable: true },
					{
						prop: 'is_published',
						label: 'Статус',
						sortable: true,
						meta: {
							boolean: { trueVal: 'Активен', falseVal: 'Не активен' }
						}
					}
				],
				operations: {
					edit: true,
					delete: true
				}
			};
		}
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar',
			// show_overlay: 'show_overlay',

			fetch_items: 'products/fetch_products',
			delete_item: 'products/delete_product',

			set_items: 'products/set_products',
			set_filters: 'products/set_products_filters',

			fetch_categories: 'categories/fetch_categories',
			set_categories: 'categories/set_categories'
		})
	},
};
</script>
